import { createAction, props } from '@ngrx/store';

import { Commissions, CommissionsRequest } from '../../models';

export const loadRequest = createAction(
  '[Commissionable] Load Request',
  props<{ request: CommissionsRequest }>(),
);

export const loadSuccess = createAction(
  '[Commissionable] Load Success',
  props<{ items: Commissions[] }>(),
);

export const loadFailure = createAction(
  '[Commissionable] Load Failure',
  props<{ error: any }>(),
);
export const updateRequest = createAction(
  '[Commissionable] Update Request',
  props<{
    request: CommissionsRequest;
    data: Commissions[];
    onSuccess?: () => void;
  }>(),
);

export const updateSuccess = createAction(
  '[Commissionable] Update Success',
  props<{ items: Commissions[] }>(),
);

export const updateFailure = createAction(
  '[Commissionable] Update Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Commissionable] Reset State');
