import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { generateParamArray } from '../core/helpers/params-generator';
import { Commissions, CommissionsRequest } from '../models';

@Injectable({
  providedIn: 'root',
})
export class CommissionsService {
  constructor(private http: HttpClient) {}

  load(request: CommissionsRequest) {
    return this.http.get(
      `properties/commissions/${request.commissionable_type}/${
        request.commissionable_id
      }?${generateParamArray('property_id', request.properties)}`,
    );
  }

  update(request: CommissionsRequest, commissions: Commissions[]) {
    return this.http.post(
      `properties/commissions/${request.commissionable_type}/${
        request.commissionable_id
      }?${generateParamArray('property_id', request.properties)}`,
      { commissions },
    );
  }
}
