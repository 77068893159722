import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { get } from 'lodash';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { ErrorHandlerService } from '../../core/services/error-handler.service';
import { CommissionsService } from '../../services/commissions.service';
import { NotificationService } from '../../ui/services/notification.service';

import * as fromActions from './actions';

@Injectable()
export class CommissionsStoreEffects {
  constructor(
    private dataService: CommissionsService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private notifications: NotificationService,
    private translate: TranslateService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ request }) =>
        this.dataService.load(request).pipe(
          map((response: IResponseSuccess) =>
            fromActions.loadSuccess({
              items: get(response, ['data', request.properties[0]]),
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateRequest),
      switchMap(({ request, data, onSuccess }) =>
        this.dataService.update(request, data).pipe(
          map((response: IResponseSuccess) => {
            if (onSuccess) {
              onSuccess();
            }
            this.notifications.push({
              title: this.translate.instant('done'),
              content: this.translate.instant('notifications.update_success', {
                param: this.translate.instant('commissions'),
              }),
              type: 'success',
            });
            return fromActions.updateSuccess({
              items: get(response, ['data', request.properties[0]]),
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );
}
